.custom-toast-error {
  background-color: #ff4848 !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 10px !important;
}

.custom-toast-error .swal2-icon {
  display: none!important;
}

.custom-toast-success {
  background-color: #147700 !important;
  color: #fff !important;
  font-size: 14px !important;
  padding: 10px !important;
}

.custom-toast-success .swal2-icon {
  display: none!important;
}